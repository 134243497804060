<template>
	<div class="bg">
		<headers :userList="userinfor"></headers>
		<div class="box">
			<div class="top">
				<div>
					<p class="num">{{fxinfor.balance}}</p>
					<p>可提现收益</p>
					<p class="extract" @click="getmoney">提现</p>
				</div>
				<div>
					<p class="num">{{fxinfor.total_money}}</p>
					<p>累计总收益</p>
					<p class="share">提示：分享商品链接，用户下单后获得佣金</p>
				</div>
				<p class="invitation" @click="$router.push('invitation')">邀请记录</p>
			</div>
			<div class="text-box">
				<el-tabs v-model="activeName" @tab-click="handleClick">
					<el-tab-pane label="待下单" name="0"></el-tab-pane>
					<el-tab-pane label="在路上" name="1"></el-tab-pane>
					<el-tab-pane label="以获得" name="2"></el-tab-pane>
					<el-tab-pane label="已失效" name="3"></el-tab-pane>
				</el-tabs>
				<div class="conent">
					<div class="title">
						<span>日期</span>
						<span>预估佣金</span>
						<span>商品名称</span>
					</div>
					<div class="list">
						<div v-for="(item,index) in list" :key="list">
							<span>{{item.ordertime}}</span>
							<span class="yj">+{{item.yongjin}}</span>
							<span>推广商品{{item.goods_name}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<publicBottom></publicBottom>
	</div>
</template>

<script>
	import headers from "./header"; // 头部 TOP 和header
	import publicBottom from "@/components/public/public_bottom"; //底部
	import {
		usergetinfo
	} from "@/request/user";
	export default {
		data() {
			return {
				userinfor: "",
				activeName: '0',
				list:"",
				fxinfor:"",
			}
		},
		components: {
			headers,
			publicBottom
		},
		created() {
			//   个人信息
			usergetinfo({
				token: this.$token
			}).then(res => {
				if (res.code == 1) {
					this.userinfor = res.data;
				}
			});
			this.getlist();
			this.getfxinfor();
		},
		mounted() {

		},
		methods: {
			handleClick(tab) {
				this.getlist();
			},
			getfxinfor(){
				this.$axios.post("/api/public/getfxinfo",{
					token:this.$token,
				}).then(res=>{
					if(res.data.code==1){
						this.fxinfor=res.data.data;
					}
				})
			},
			getlist(){
				this.$axios.post("/api/public/fxlist",{
					token:this.$token,
					status:this.activeName,
				}).then(res=>{
					console.log(res);
					if(res.data.code==1){
						this.list=res.data.data.goods_data;
					}
				})
			},
			getmoney(){
				this.$router.push({name:"我的钱包",query:{state:2}});
			}
		}
	}
</script>

<style lang="less" scoped>
	/deep/.el-tabs__item.is-active{
		color:#4EEEC8;
	}
	/deep/.el-tabs__active-bar{
		background-color:#4EEEC8;
	}
	/deep/.el-tabs__item:hover{
		color:#4EEEC8;
	}
	.bg {
		background-color: #F5F5F5;
	}

	.box {
		width: 1200px;
		margin: 20px auto;

		.top {
			width: 100%;
			height: 200;
			padding: 20px;
			background-color: #fff;
			display: flex;
			justify-content: space-around;
			position: relative;
			border-bottom: 2px solid #E5E5E5;

			div {
				p {
					margin: 20px 0;
				}

				.num {
					font-size: 29px;
				}
			}

			.extract {
				color: #4EEEC8;
				cursor: pointer;
			}

			.share {
				color: #B3B3B3;
			}

			.invitation {
				color: #4EEEC8;
				position: absolute;
				right: 20px;
				top: 20px;
				cursor: pointer;
			}
		}

		.text-box {
			background-color: #fff;

			/deep/.el-tabs__header {
				margin: 0 !important;
			}
			/deep/.el-tabs__active-bar{
				width:110px;
			}
			/deep/.el-tabs__item{
				width: 110px;
				text-align: center;
			}
			.conent {
				background-color: #fff;

				.title {
					background-color: #F7F7F7;
					height: 50px;
					line-height: 50px;
					display: flex;
					justify-content: space-around;
				}
				.list{
					height: 300px;
					overflow: auto;
					div{
						line-height: 50px;
						background-color: #fff;
						span{
							display: inline-block;
							width: 33.33333%;
							text-align: center;
						}
					}
					div:nth-child(even){
						background-color: #f5f5f5;
					}
					.yj{
						color:#4EEEC8;
					}
				}
			}
		}

	}
</style>
